.model-bg-transparent .modal-content {
    background: transparent;
}

/* White background tooltip */
.tooltip {
    background-color: #fff !important;
    color: #333 !important;
    border: 1px solid #986868 !important;
    border-radius: 4px !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
}

.tooltip .tooltip-inner {
    padding: 10px !important;
}

[dir] .my-custom-toggle {
    background-color: #ffffff !important; /* Set your desired color with !important to override */
    border: 1px solid #dddddd !important; /* A border color of your choice */
    border-color: #dddddd !important;
    color: #333333 !important; /* Text color */
    width: 190px !important;
    /* Add any other styles you need */
}

[dir] .dropdown button {
    /* A border color of your choice */
    border-color: #dddddd !important;
}

.animatedSelect {
    position: relative;
    width: 200px;
    z-index: 50;
}

.circle-button {
    border: none;
    outline: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 24px;
    transition: all 0.5s ease;
    /* Add your button styling */
}

.react-select-container {
    opacity: 0;
    animation: fadeInSelect 0.5s ease forwards;

    /* Adjust width and position if needed */
}

.react-select__control--is-focused {
    outline: none !important; /* Removes the default blue border */
    border-color: red !important; /* Keeps the border red when focused */
    box-shadow: none !important;
}

.react-select__clear-indicator {
    color: red; /* Style your clear indicator */
}

.react-select-container-leave {
    animation: fadeOutSelect 0.5s ease forwards;
}

@keyframes fadeOutSelect {
    from {
        transform: scale(1);
        opacity: 1;
    }
    to {
        transform: scale(0.5);
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes fadeInSelect {
    from {
        transform: scale(0.5);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.custom-pop .popover-inner {
    background-color: #fff !important;
    min-width: 450px !important;
}

.bs-popover-auto {
    min-width: 466px !important;
}

.round-none {
    border-radius: 0px !important;
}

.text-primary-color {
    color: var(--primary-color) !important;
}

button {
    border-radius: 0px !important;
}

.navbar-expand-sm .navbar-nav {
    justify-content: center;
}

.rdt_TableCell input:checked {
}
*/



/*  Make Regular  font weight Placeholder for all inputs :  */


.form-control:focus {
    border: 1px solid #babfc7 !important;
}
.form-control::placeholder {
    font-weight: 300 !important;
}

.rdt_TableRow .rdt_TableCell:first-child input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid var(--primary-color);
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    background-color: transparent !important;
    position: relative;
}

.rdt_TableRow .rdt_TableCell:first-child input[type='checkbox']:checked {
    background-color: var(--primary-color) !important;
}

.rdt_TableRow .rdt_TableCell:first-child input[type='checkbox']::before {
    content: '';
    display: none;
}

.rdt_TableRow .rdt_TableCell:first-child input[type='checkbox']:checked::before {
    content: '\2713';
    font-size: 12px;
    color: white;
    display: block;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.rdt_TableRow .rdt_TableCell:first-child input[type='checkbox']:hover {
    border-color: var(--primary-color);
}

.rdt_TableRow .rdt_TableCell:first-child input[type='checkbox']:focus {
    box-shadow: 0 0 0 3px lighten(var(--primary-color), 30%);
}

.rdt_TableHeadRow input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    background-color: transparent !important;
    position: relative;
}

.rdt_TableHeadRow input[type='checkbox']:checked {
    background-color: #fff !important;
}

.rdt_TableHeadRow input[type='checkbox']::before {
    content: '';
    display: none;
}

.rdt_TableHeadRow input[type='checkbox']:checked::before {
    content: '\2713';
    font-size: 12px;
    font-weight: 700;
    color: var(--primary-color);
    display: block;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.rdt_TableHeadRow input[type='checkbox']:hover {
    border-color: white;
}

.rdt_TableHeadRow input[type='checkbox']:focus {
    box-shadow: 0 0 0 3px lighten(var(--primary-color), 30%);
}

/*  Include styles for table search Element and Set the icon  */

.Tbl_search_input input::placeholder {
    font-weight: 500 !important;
    color: #82868b !important;
    opacity: 1 !important;
}

.Tbl_search_input .icon {
    top: 50%;
    transform: translateY(-50%);
    /* left: 5px; */
    /*  Todo: Make it right to move the position into right side :  */
    right: 8px !important;
    fill: #82868b !important;
}

.Tbl_search_input .ico-close {
    top: 45%;
    transform: translateY(-30%);
    /* left: 8px; */
    /*  Todo: Make it right to move the position into right side :  */
    right: 8px !important;
    cursor: pointer;
}

.non-selected-item {
    color: #808080 !important;
    font-weight: 400 !important;
}

.selected_booking .badge {
    background: var(--primary-color) !important;
    color: white !important;
}

.textTruncate {
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    /* width: 100%;  */
}

/* Assuming you have a checkbox with the ID 'remember_me' */
#remember-me {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    outline: none !important;
    cursor: pointer !important;
    background-color: #fff !important;
    border: 1px solid #ea2224 !important;
}

#remember-me:checked::before {
    content: '\2713' !important; /* Unicode character for the tick mark */ /* Tick mark color */
    color: white !important;
    background-color: #ea2224 !important;
    width: 16px !important;
    height: 17px !important;
    padding: 0px 3px 1px 3px !important;
    display: block;
}

#toggle-columns {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    outline: none !important;
    cursor: pointer !important;
    /* background-color: #fff !important; */
    border: 1px solid #ea2224 !important;
}

#toggle-columns:checked::before {
    content: '\2713' !important; /* Unicode character for the tick mark */ /* Tick mark color */
    color: white !important;
    background-color: #ea2224 !important;
    width: 16.5px !important;
    height: 16.5px !important;
    padding: 0px 3px 1px 3px !important;
    display: block;
}

#checkbox-all-toggle {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    outline: none !important;
    cursor: pointer !important;
    background-color: #fff !important;
    border: 1px solid #ea2224 !important;
}

#checkbox-all-toggle:checked::before {
    content: '\2713' !important; /* Unicode character for the tick mark */ /* Tick mark color */
    color: white !important;
    background-color: #ea2224 !important;
    width: 16.5px !important;
    height: 16.5px !important;
    padding: 0px 3px 1px 3px !important;
    display: block;
}

/*   Date Picker Dropdown Custome color */

.react-datepicker__input-container {
    width: 100%;
}
.react-datepicker__input-container input {
    width: 100% !important;
    padding: 8px 20px !important;
    outline: 0 !important;
    border: 1px solid #babfc7;
}

.react-datepicker-popper,
.react-datepicker-popper div {
    width: 100% !important;
}

.react-dateTimePickC .react-datepicker-wrapper {
    margin-top: 2px !important;
}
.dateTimePickC input:focus {
    outline: 0 !important;
}
.react-datepicker--time-only .react-datepicker__time-container {
    width: 100% !important;
    border: none !important;
    border-radius: 5px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100% !important;
    font-weight: 500;
}

.input.react-datepicker-ignore-onclickoutside {
    width: 100%;
    padding: 10px 20px;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box li {
    width: 100% !important;
    font-weight: 500;
    display: block;
}

.react-dateTimePickC ::-webkit-scrollbar {
    width: 12px !important;
}
.react-dateTimePickC ::-webkit-scrollbar-thumb {
    border-radius: 5px !important;
    background-clip: padding-box !important;
    border: 1px solid transparent !important;
    padding: 0px 10px;
}

.react-dateTimePickC .icon {
    right: 10px !important;
    transform: translateY(-50%) !important;
    top: 66%;
}
.react-dateTimePickC .react-datepicker__triangle {
    display: none;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: auto !important;
    padding: 15px 20px !important;
    font-weight: 600;
    font-size: 14px;
    font-family: inter;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background: #ea2224 !important;
    color: white;
}
.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected:hover {
    background: #ea2224 !important;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    background-color: #fde8e9 !important;

    color: #ea2224;
}

.react-datepicker__header {
    display: none;
    border-bottom: 0px;
}

.Card__title {
    font-size: 20px;
    color: #ea2224;
    font-weight: 500;
    margin-bottom: 40px;
}
.Card_title_new {
    font-size: 20px;
    color: #ea2224;
    font-weight: 500;
}

/* .card_titleUnSelect{
     color: #ea2224   !important; 
} */
.card_titleUnSelect {
    color: #868686 !important;
    font-weight: 400;
}

.card_titleUnSelect_light {
    color: #868686 !important;
    font-weight: 400;
}

.fileInputContainer input[type='text']::placeholder {
    width: 80% !important;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.css-1rkktnb-multiValue {
    background: rgba(220, 20, 60, 0.2) !important;
}
.css-1rkktnb-multiValue .select__multi-value__label {
    color: rgba(39, 39, 39, 1) !important;
}
.css-1rkktnb-multiValue .css-tj5bde-Svg {
    fill: #ed2124;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5em !important;
    height: 1.5em !important;
}

p {
    font-family: 'Lexend' !important;
}

/*  Modal Of View Image And Files  */

/* .modal_overflow_title {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 98%;
    min-height: 29px;
    margin: 0 auto;
}

.modal-title-container {
    padding: 0px 15px !important;
}



.modal_overflow_title .swiper-slide {
    max-width: max-content !important;
}




 */

.navItemFile {
    font-weight: 400;
    font-size: 16px;
}

.fileModalTitleText {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 18px;
}

.unSelectedNavigationItems {
    display: block;
    padding-bottom: 5px;
    text-align: center;
}

.selectedNavigationItems {
    border-bottom: 3px solid var(--primary-color) !important;
    display: block;
    padding-bottom: 6px;
    text-align: center;
    margin-bottom: 2px !important;

    transition: 0.3s all ease;
}

.modal_overflow_title {
    border-bottom: 1px solid #dfdfdf;
}

.mySwiper1 .swiper-wrapper {
    overflow: scroll !important;
    margin-bottom: -20px !important;
}

.AnimateSelection2 {
    margin-top: -3px !important;
}

.image-modal-body {
    padding: 8px 32px 24px 32px;
    min-height: 300px;
}

.scrollable-img-container {
    max-height: 400px; /* Adjust based on the modal size and what you want */
    overflow-y: auto;
    margin-top: 1.2rem;
}
.main-menu.menu-light .navigation > li ul .active > * {
    color: #fff !important;
}

.wb-url {
    color: #333;
}
.wb-url:hover {
    text-decoration: underline;
}
@media screen and (max-width: 1024px) {
    .rp-left-col-border {
        padding-top: 24px;
        margin-top: 24px;
        padding: 20px 0px !important;
        border-top: 1px solid #dfdfdf !important;
    }

    /*  To Control the modal Responsive ness in mobile and tablet  */

    .mobileModal .modal-content .modal-body-container {
        height: 80dvh !important;
        overflow: hidden !important;
    }

    .mobileModal .modal-content .modal-body {
        overflow: auto !important;
        height: 100% !important;
    }

    .mobileModal .modal-content {
        width: 90% !important;
        margin: 0 auto;
    }

    .alertModalCustom .modal-content .model-content-alert {
        height: 80dvh !important;
        overflow: hidden !important;
    }
    .alertModalCustom .body-wrapper {
        height: 100% !important;
        overflow: scroll !important;
    }
    .alertModalCustom .modal-content {
        width: 90% !important;
        margin: 0 auto;
    }

    .body-wrapper::-webkit-scrollbar .modal-body::-webkit-scrollbar {
        width: 0px !important;
        background: transparent;
        scrollbar-width: none;
    }
    .body-wrapper::-webkit-scrollbar-thumb .modal-body::-webkit-scrollbar-thumb {
        width: 0px !important;
        background: transparent;
        scrollbar-width: none;
    }

    /*  Menu bar transition */
    /* .menu-duration {
        transition: 0.5s all ease;
    } */
}

@media screen and (max-width: 1024px) {
    /*  Change the section width  */
    /* .react-select {
        width: 98% !important;
    } */

    .my-react-selection {
        width: 98% !important;
    }

    /* .input-group {
        width: 98% !important;
        margin: 0 auto;
    } */

    .flatPicker_date .form-control:not(:last-child) {
        border: 1px solid #ddd;
    }

    .w98 .input-group {
        width: 95%;
    }

    .filter .dropdown-menu {
        width: auto !important;
    }
    .select_filter_date .dropdown-menu {
        width: 300px !important;
        left: 0px !important;
    }
}

@media screen and (max-width: 768px) {
    .popover-inner {
        background-color: #fff !important;
        max-width: 280px !important;
    }

    .bs-popover-auto {
        width: auto !important;
        min-width: 270px !important;
        max-width: 280px !important;
    }
}

.main-menu.menu-light .navigation > li .active {
    background: var(--primary-color) !important;
    color: #fff !important;
}

.main-menu.menu-light .navigation > li .active > span {
    color: white !important;
}

/*   Here All of our css for selection  */
.colorBox {
    width: 16px;
    height: 16px;
    border-radius: 50%;
}

.pickup_modal .css-b62m3t-container {
    width: 220px !important;
}

.css-1fdsijx-ValueContainer .colorBox {
    display: none !important;
}

.badge-job1 {
    padding: 8px !important ;
    border-radius: 4px !important;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    min-width: 200px;
    color: white;
}

/*  All Button Color Setup Here */

[dir] .view-proof-bedge:hover {
    background: rgb(109 136 235) !important;
    padding: 7px 15px !important;
    border-radius: 20px !important;
    cursor: pointer;
}

/* TODO:m Uncomment This Code, If pending-verification  class needs hover */
/* [dir] .pending-verification:hover {
    background: #ff0000 !important;
    padding: 7px 15px !important;
    border-radius: 20px !important;
    cursor: pointer;
     
} */

.table-bottom {
    display: flex;
    align-items: start;
    justify-content: space-between !important;
    padding-bottom: 32px;
}
.table_bottom_right {
    width: 233px;
}
.table-bottom h6 {
    margin-bottom: 0px !important;
    margin-top: 0px;
}

.booking_details_wrap {
    border-top: 0.5px solid #d7dae0;
    border-bottom: 0.5px solid #d7dae0;
    padding: 23px 0px;
}

.table-bttom_left {
    width: 159px;
}

.sub_customer_btm_m {
    margin-bottom: 20px !important;
}

.invoice-modal .modal-content {
    width: 90% !important;
    margin: 0 auto;
    background-color: rgb(206 206 206) !important;
}

.edit_modal_booking {
    background: #ea2224 !important;
    padding: 8px 16px !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    border: none;
}

.addBookingModal_edit_page .modal-body {
    padding: 32px !important;
}

.addBookingModal_edit_page .card-header {
    border: 1px solid #a9a9a9 !important ;
    border-radius: 0px !important;
}
.addBookingModal_edit_page .react-dataTable {
    border: 1px solid #a9a9a9 !important ;
    border-top: none !important;
}
.addBookingModal_edit_page .sc-feUZmu.jzrolw.react-dataTable {
    border: none !important;
    border-bottom: 1px solid #a9a9a9 !important ;
    /* border-top: none; */
}

.invoice_parent_modal_hide .close_btn_special {
    display: none !important;
}

.vendorStatement_select_title {
    border-bottom: 3px solid #ea2224;
}

.not_allowed {
    cursor: not-allowed;
    opacity: 0.5;
}

.allowed_cursor {
    cursor: pointer;
    opacity: 0.5s;
}

/*  Address Overlapping CSS */
.manage_address_isAddressModal {
    /* border: 1px solid #d4d4d8 !important; */
    border-radius: 0px !important;
    padding: 0px !important;
}
.manage_address_isAddressModal .modal-content {
    border-radius: 0px !important;
    padding: 0px !important;
}
.manage_address_isAddressModal .modal-content > div {
    padding: 0px !important;
}
.manage_address_isAddressModal .modal-body {
    padding: 0px !important;
}
.manage_address_isAddressModal .address_column_wrapper {
    padding: 0px !important;
}

@media screen and (min-width: 720px) {
    .manage_address_isAddressModal .address_form_height {
        /* height: '420px'; */
    }
}

.driver_hire_btn {
    width: 110px;
    color: white;
    border-radius: 60px !important;
    border: none !important;
    padding: 7px 0px !important;
    text-transform: capitalize;
    font-weight: 500;
}

.driver_hire_btn_status {
    width: 'auto';
    font-weight: 500;
    font-size: 16px;
    padding: 4px 20px;
    border: none;
    border-radius: 60px !important;
    color: #fff;
}

.driver_hire_modal .modal-body {
    padding: 0px 0px !important;
}

.grid_items_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px 40px;
    column-count: 3;
}

.driver_hire_sidebarShow {
    position: absolute;
    top: 0px;
    right: 0px;
    box-shadow: 10px 10px 28px #000000;
    background: '#fff';
    height: 100vh;
    overflow: hidden;
    z-index: 99999;
    transition: 0.5s all ease;
}
.driver_hire_hide {
    position: absolute;
    top: 0px;
    right: -999999px;
    box-shadow: 10px 10px 28px #000000;
    background: '#fff';
    height: 100vh;
    overflow: hidden;
    z-index: 99999;
}

.driver_tab_border {
    border-bottom: 2px solid #ed2124 !important;
}

.driver_joined_status .css-t3ipsp-control {
    width: 200px !important;
    background: none;
}

.divider.vendor_Payment_divider {
    margin: 0px 0px !important;
}

.vendor_Payment__Element .file_input_choose_file {
    color: #afafaf !important ;
}

.vendor_Payment__Element .vender_paymentElement_input::placeholder {
    font-size: 16px;
}

.vendorPaymentElement .css-1xc3v61-indicatorContainer {
    display: none;
}

/* .driver_hire_select_option {
    border: none !important;
    outline: none !important;
    text-align: center;
}
.driver_hire_select_option option {
    padding: 12px 16px !important;
    font-size: 18px !important;
} */

.driver_hire_popup_select .custom-dropdown {
    position: relative;
    width: 200px;
}

.driver_hire_popup_select .custom-dropdown__selected {
    /* padding: 12px 16px; */
    /* background-color: #f5f5f5; */
    /* border-radius: 4px; */
    /* border: 1px solid #ccc; */
    cursor: pointer;
    font-size: 20px;
    text-align: center;
    justify-content: center;
}

.driver_hire_popup_select .custom-dropdown__options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4), 0 4px 6px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    transform: translateY(10%);
}

.driver_hire_popup_select .custom-dropdown__option {
    padding: 12px 16px;
    cursor: pointer;
    font-size: 20px;
}

.driver_hire_popup_select .custom-dropdown__option:hover {
    background-color: #ea2224;
    color: #fff;
}

.driver_hire_popup_select .highlight {
    background-color: #ea2224;
    color: #fff;
}
.driver_hire_popup_select .disabled {
    cursor: not-allowed;
}

.driver_hire_status_wrapper {
    height: 345px !important;
}

@media screen and (min-width: 768) {
    .driver_hire_status_wrapper {
        height: 100% !important;
    }
}

.second_level_language_status {
    font-size: 16px;
    font-weight: 500;
}

.language_proficiency_table td,
th {
    border: 1px solid #e9e9e9;
    text-align: center;
    vertical-align: center;
    padding: 10px 24px;
    text-transform: capitalize;
}

/** TODO: All UI Fixing From September 2024 By Rahaman */

.select--is-disabled {
    background: rgb(252 222 220 / 30%) !important;
}

:where(.css-dev-only-do-not-override-14bt1ku).ant-picker-outlined:focus,
:where(.css-dev-only-do-not-override-14bt1ku).ant-picker-outlined:focus-within,
:where(.css-dev-only-do-not-override-14bt1ku).ant-picker-outlined:hover {
    border-color: #d8d6de !important;
}
.anticon-close-circle {
    fill: #ea2224 !important;
}

.flat_Picker_date_input .form-control:focus {
    border-right: none !important;
}

.ico-close-right {
    right: 0 !important;
    position: absolute !important;
    display: inline;
}

.driverLocationReasonElement .ant-input-disabled {
    background: #fee9ea;
}
.btn.disabled {
    opacity: 0.45 !important;
    cursor: not-allowed !important;
}

/* Spinner Styles */
.btn_label_spinner {
    border: 4px solid rgba(255, 255, 255, 0.5);
    border-left-color: #fefeff;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    animation: spinBtn 1s linear infinite;
}

@keyframes spinBtn {
    to {
        transform: rotate(360deg);
    }
}

.rad-btn:disabled {
    background: #fcdfdf;
    opacity: 1 !important;
}

.badge {
    opacity: 1 !important;
    transition: 0.5s all;
}
.badge:hover {
    opacity: 0.85 !important;
}

.download_export_pdf_button {
    transition: all 0.5s;
}
.download_export_pdf_button:hover {
    color: #ea2224 !important;
}
.download_export_pdf_button:focus {
    color: #ffffff !important;
}

.filter_padding_sepecial {
    padding: 11px 13px 10px 0px !important;
}

.text-wrap-with-break {
    text-wrap: wrap !important;
    word-break: break-all;
}

.odometer_styles {
    margin-top: 5px !important;
}
.odometer_styles_modal {
    margin-top: -5px !important;
}

@media screen and (max-width: 768px) {
    .odometer_styles {
        margin-top: -10px !important;
    }
    .odometer_styles_modal {
        margin-top: -10px !important;
    }
}

.client_invoice_text_field {
    height: 122px !important;
}

.select__placeholder {
    text-transform: capitalize !important;
}

.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li > .dropdown-menu a:hover {
    background: #ea2224 !important;
    color: white !important;
}

.dropdown-submenu.dropdown-toggle.d-flex.align-items-center.dropdown-item:focus {
    color: #ea2224 !important;
}

.dropdown-submenu.dropdown-toggle.d-flex.align-items-center.dropdown-item:hover {
    background: #ea2224 !important;
}

/*  Failed on Delivery Badge  */
.failed_on_delivery_badge {
    background: #293592 !important;
    color: white !important;
}

.shipment_chips_badge {
    /* background: #ff9800 !important;
    color: white !important; */
    background-color: #ffebcc !important; /* Lighter orange background */
    color: #ff9800 !important; /* Darker orange text */
}

.dedicated_chips_badge {
    /* background: #673ab7 !important;
    color: white !important; */
    background-color: #ede7f6 !important; /* Lighter purple background */
    color: #673ab7 !important; /* Dark purple text */
}
.outstation_chips_badge {
    /* background: #ff7043 !important;
    color: white !important; */
    background-color: #ffe0cc !important; /* Lighter orange background */
    color: #ff7043 !important; /* Dark orange text */
}
.local_chips_badge {
    /* background: #617d8b !important;
    color: white !important; */
    background-color: #d5dde1 !important; /* Lighter blue-gray background */
    color: #617d8b !important; /* Dark blue-gray text */
}

.text_no_wrap {
    white-space: nowrap !important;
}

.view_avater_icon {
    background: #2196f3 !important;
}

.do_number_card_body .card-body {
    padding: 0px 0px !important;
}
.edit_avater_icon {
    background: #6f42c1 !important;
}
.delete_avater_icon {
    background: #ed4343 !important;
}

.calc_avater_icon {
    background: #008080 !important;
}
.approved_avater_icon {
    background: #2fa94e !important;
}

/*  Disabled Color Changed Here  Select Input*/
.select--is-disabled {
    background: #e5e5e5 !important;
}

[dir] .select__control.select__control--is-disabled,
[dir] .react-select__control.select__control--is-disabled {
    background: #e5e5e5 !important;
}
.select__control.select__control--is-disabled .select__indicator svg,
.react-select__control.select__control--is-disabled .select__indicator svg {
    color: #b0b0b0 !important;
}

/*  Radio button Component : Disabled  */
button.btn-icon.rad-btn.d-flex.gap-1.d-flex.align-items-center.justify-content-start.radio-button.btn.btn-outline-\#ededed.disabled {
    background: #e5e5e5 !important;
}

button.btn-icon.rad-btn.d-flex.gap-1.d-flex.align-items-center.justify-content-start.radio-button.btn.btn-outline-\#ededed.disabled
    input[type='radio']
    + label:before {
    border-color: #b0b0b0;
    border-radius: 50%;
}

button.btn-icon.rad-btn.d-flex.gap-1.d-flex.align-items-center.justify-content-start.radio-button.btn.btn-outline-\#ededed.disabled
    input[type='radio']
    + label:after {
    background-color: #b0b0b0;
    border-radius: 50%;
}

/*  Booking Date + Booking Time  */
input[disabled] ~ .input-group-2 img {
    filter: grayscale(100%) brightness(70%); /* Apply grayscale and adjust brightness */
}

[dir] :where(.css-dev-only-do-not-override-14bt1ku).ant-picker-outlined.ant-picker-disabled,
[dir] :where(.css-dev-only-do-not-override-14bt1ku).ant-picker-outlined[disabled] {
    background: #e5e5e5 !important;
    border-color: #d9d9d9 !important;
}

:where(.css-dev-only-do-not-override-14bt1ku).ant-picker .ant-picker-input > input[disabled] {
    background-color: #e5e5e5;
    color: #000000;
    cursor: pointer;
}

.m_right_50 {
    margin-right: 0.5rem !important;
}

.user_permission_select_input .custom-select__control {
    border-color: #d8d6de !important;
    box-shadow: 0 0 0 1px #d8d6de !important;
}
.user_permission_select_input .custom-select__control:focus {
    border-color: #d8d6de !important;
    box-shadow: 0 0 0 1px #d8d6de !important;
}
.user_permission_select_input .custom-select__control .css-114f3ok-indicatorSeparator {
    background-color: #e5e5e5 !important;
}

.custom-badge-job {
    padding: 7px 8px !important;
    border-radius: 20px !important;
}

.unpaid_chips {
    background-color: #f8d1d1 !important; /* Lighter red background */
    color: #f16363 !important; /* Darker red text */
    padding: 7px 15px !important;
    border-radius: 20px !important;
    cursor: default !important;
}

.custom-badge-job:hover {
    /*  Custom Badege Hover have to be 100% opacity */
    opacity: 100% !important;
}
.paid_chips {
    background-color: #e5f8ed !important; /* Brighter and more vibrant green */
    color: #28c76f !important; /* White text for contrast */
    padding: 7px 15px !important;
    border-radius: 20px !important;
    cursor: none !important; /* Prevents cursor change */
}

.unpaid_chips:hover,
.paid_chips:hover {
    opacity: 100% !important;
}

.chip-light__blue {
    background-color: #85c1ff !important;
    color: #004b87 !important;
    padding: 7px 15px !important;
    border-radius: 20px !important;
}

.secondary_dynamic_badge {
    background-color: rgba(40, 199, 111, 0.1) !important; /* Light green background */
    color: rgba(40, 199, 111, 1) !important; /* Green text */
}

.custom-badge-job:hover {
    opacity: 1 !important;
}
.table_dropdown_menu .dropdown-item:focus {
    color: #ea2224 !important;
}

