//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding component variables.
//  ================================================================================================

.loading {
  color: #ea2224;
}

.custom-label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.required {
  color: #ea2224;
}
