/*================================================================================
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

// customize the leaft navigation

:root {
    --primary-color: #ea2224;
    --primary_dark: #272727;
    --primary_white: #ffffff;
}

// ------------ FONT ----------------

@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

* {
    font-family: 'Lexend', sans-serif;
}

// -------------

.navigation li {
    padding: 8px 0;
}

.navigation li a::after {
    contain: '';
    margin-top: 9px;
}

.navbar-container .nav-search.nav-item {
    list-style: none;
}

// webkit scrollbar
*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
    width: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
}

// old primary color - 0c32bd
*::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px var(--primary-color);
}

/* Specific scrollbar styles for nested elements with specific classes */
.sc-feUZmu.jzrolw.react-dataTable::-webkit-scrollbar {
    /* Custom styles for the scrollbar track */
    width: 12px; /* Example: Narrower width */
    height: 24px;
    border-radius: 0px !important;
    cursor: pointer;
    // background-clip: padding-box;
}

.sc-feUZmu.jzrolw.react-dataTable::-webkit-scrollbar-thumb {
    /* Custom styles for the scrollbar thumb */
    background-color: #ea2224; /* Example: Red thumb color */
    cursor: pointer;
    width: 30% !important;
    border-radius: 0px !important;

    /* Add other styles as needed */
}

/* Specific scrollbar styles for nested elements with specific classes */
.sc-feUZmu.eguvnT.react-dataTable::-webkit-scrollbar {
    /* Custom styles for the scrollbar track */
    width: 12px; /* Example: Narrower width */
    height: 24px;
    border-radius: 16px;
    cursor: pointer;
    border-radius: 0px !important;
    // background-clip: padding-box;
}

.sc-feUZmu.eguvnT.react-dataTable::-webkit-scrollbar-thumb {
    /* Custom styles for the scrollbar thumb */
    background-color: #ea2224; /* Example: Red thumb color */
    cursor: pointer;
    width: 30% !important;
    border-radius: 0px !important;
    /* Add other styles as needed */
}

// table header color
.rdt_TableHeadRow {
    background-color: var(--primary-color);
    color: white;
}

.gocYik .sc-aXZVg .jNIkof {
    max-height: 40px !important;
    max-height: 40px !important;
    height: 40px;
}

.sc-gEvEer .hQPXLq .rdt_TableHead {
    max-height: 40px !important;
    max-height: 40px !important;
    height: 40px;
}

/* utils */
.bg-img {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
}

.fit {
    width: fit-content;
}

.csvReader {
    max-height: 40px;
    display: flex;
    justify-content: center;
}

.acceptedFile {
    border: '1px solid #ccc';
    height: 45;
    line-height: 2.5;
    padding-left: 10px;
    max-width: 100px;
    overflow: hidden;
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.csvRemove {
    color: var(--primary-color);
    text-align: center;
    display: flex;
    align-items: center;
    /* margin-top: 8px; */
    cursor: pointer;
}

.csvFile {
    display: flex;
    align-items: center;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 6px;
}

/* .form-check-input {
  width: 0.8rem;
  height: 0.8rem;
} */

.MuiTextField-root {
    width: 100%;
    position: relative !important;

    div {
        display: flex;
        width: 100%;
        margin-top: -6px;
        align-items: center !important;
    }

    button {
        margin-top: 6px !important;

        margin-right: -100px !important;
    }
}

// .MuiClock-root
// .MuiClockPicker-root
.MuiClock-root {
    background-color: #fff !important;
    border-radius: 6px;
    position: absolute !important;
    top: 25vh !important;
    left: 43vw !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
}

.mui-time-picker {
    position: relative !important;
    background-color: var(--primary-color);
}

// modify year picker primary color
.rdtActive {
    background-color: var(--primary-color) !important;
}

.mainLogo {
    margin-left: 27px !important;
    margin-top: -9px !important;
}

.modal {
    height: 100vh !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    .modal-dialog {
        margin: 0 !important;
    }
}

.accordion-item {
    border-bottom: 1px solid #ebe9f1 !important;
}

.form-switch {
    input {
        width: 55px !important;
    }

    span {
        font-size: 12px !important;
        margin-top: 2px !important;
    }
}

label {
    margin-bottom: 0.375rem !important;
}

.payment {
    input {
        padding-left: 10px !important;
    }
}

.live-btn {
    position: absolute;
    bottom: 108px;
    z-index: 999;
    cursor: pointer;
    left: 16px;
}

@media screen and (min-width: 600px) {
    .right-accordion {
        position: fixed !important;
        right: 13px;
    }
}

@media screen and (min-width: 1190px) {
    .right-accordion {
        width: 27% !important;
    }
}

.fixed-input-icon {
    top: 10px;
    right: 10px;
}

.group-button {
    div {
        padding: 0 !important;
    }

    button:first-child {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    button:last-child {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }
}

.booking-detail {
    border-color: 1px solid #d7d7d7 !important;

    .head {
        border-bottom: 1px solid #d7d7d7 !important;
    }
}

.badge-job {
    padding: 7px 8px !important;
    border-radius: 20px !important;
}

.popup-class {
    p {
        font-size: 12px !important;
        margin: 0 !important;
    }
}

// -------

.verify-otp .spinner-border {
    display: none;
}

.otpInput {
    margin: 1rem 8px;

    input {
        margin: auto;
        width: 50px !important;
        height: 50px !important;
        border-radius: 5px;
        outline: none;
        border: 2px solid #ddd;
        font-size: 22px;
    }
}

.btn-disabled {
    cursor: not-allowed !important;
}

// -------------------

.tab-close-icon {
    position: absolute;
    top: -6px;
    right: -6px;
    cursor: pointer;
    padding: 3px 4px;
    z-index: 1;
    color: #fff;
    background-color: var(--primary-color);
    border-radius: 50%;
}

.bg-light-primary {
    background-color: #ffddde !important;
}

.w-98 {
    width: 98%;
}

.bg-white {
    background-color: #fff !important;
}

.btn-light {
    background: #fff !important;
    color: var(--primary-color) !important;
}

.dropdown button {
    background-color: #fff !important;
    border-color: var(--primary-color) !important;
}
.dropdown button:hover {
    box-shadow: none !important;
}

.dropdown button:focus {
    background-color: #fff !important;
}

// form-label
.form-label {
    color: #868686 !important;
}

// select all input placeholder color
::placeholder {
    color: #afafaf !important;
}

// select all input color
input {
    color: #272727 !important;
}

// all card-title
.card-title {
    color: #ea2224 !important;
}

.menu-item {
    color: #272727;
}

// // nav-item is active
.nav-item .active #options * {
    color: #fff;
}

.group-icons {
    color: #272727 !important;
}

// ------------ phone input -------

.phone2 {
    min-height: 37px;
    // width: auto !important;
    width: 100% !important;
    padding-right: 25px;
}

.btn-text-primary {
    color: #ea2224 !important;
}

.errorAlert {
    color: #ea5455 !important;
    font-size: 0.875rem !important;
}

.amount-input {
    border-color: #d3d3d3 !important;
    border-radius: 0px;
    border-width: 1px;
    padding: 6px 12px;
    width: 6rem !important;
    outline: none !important;
}

.amount-input:disabled {
    background-color: #50c87833;
}

.amount-input-success {
    border-color: #d3d3d3 !important;
    border-radius: 0px;
    border-width: 1px;
    padding: 6px 12px;
    width: 6rem !important;
    outline: none !important;
}

.amount-input-success:disabled {
    background-color: #50c87833;
}
.amount-input-danger {
    border-color: #d3d3d3 !important;
    border-radius: 0px;
    border-width: 1px;
    padding: 6px 12px;
    width: 6rem !important;
    outline: none !important;
}

.amount-input-danger:disabled {
    background-color: #f51d2c33;
}

.account-button {
    color: white !important;
}

.proof-chip-container {
    border: 1px solid rgba(0, 0, 8, 0.3);
    padding: 1px;
    border-radius: 5px;
}

.proof-image {
    height: 21rem !important;
}

.view-proof-bedge {
    // background-color: rgba(50, 88, 228, 1);
    background-color: #2196f3;
    color: white;
    padding: 7px 15px !important;
    border-radius: 20px !important;
    display: inline-block;
    font-size: 85%;
    font-weight: 600;
    line-height: 1;
    box-sizing: border-box;
    cursor: pointer;
}

.view-proof-disabled-badge {
    background-color: #f08080;
    color: white;
    padding: 7px 15px !important;
    border-radius: 20px !important;
    display: inline-block;
    font-size: 85%;
    font-weight: 600;
    line-height: 1;
    box-sizing: border-box;
    cursor: not-allowed;
}

.view-proof-bedge1 {
    background-color: RGB(40, 199, 111);
    color: white;
    padding: 7px 15px !important;
    border-radius: 20px !important;
    cursor: pointer;
    display: inline-block;
    font-size: 85%;
    font-weight: 600;
    line-height: 1;
    box-sizing: border-box;
}
// TODO:m If we needs to change the color to green background then we will remove this css:
.view-proof-bedge_user {
    background-color: #33a94226;
    color: #33a942;
    padding: 7px 15px !important;
    border-radius: 20px !important;
    // cursor: pointer;
    display: inline-block;
    font-size: 85%;
    font-weight: 600;
    line-height: 1;
    box-sizing: border-box;
}

.verify_booking_badge1 {
    background-color: RGB(40, 199, 111);
    color: white;
    padding: 7px 15px !important;
    border-radius: 20px !important;
    cursor: pointer;
    display: inline-block;
    font-size: 85%;
    font-weight: 600;
    line-height: 1;
    box-sizing: border-box;
}

.verify_booking_badge {
    background-color: #33a942 !important;
    color: white;
    padding: 7px 15px !important;
    border-radius: 20px !important;
    cursor: pointer;
    display: inline-block;
    font-size: 85%;
    font-weight: 600;
    line-height: 1;
    box-sizing: border-box;
}

.view-proof-bedge:disabled,
.view-proof-bedge1:disabled {
    // background-color: #f08080 !important;
    background-color: #4caf50 !important;
    cursor: not-allowed;
}

.view-proof-bedge:disabled:hover,
.view-proof-bedge1:disabled:hover {
    // background-color: #f08080 !important;
    background-color: #4caf50 !important;
    cursor: not-allowed;
}

.pending-verification {
    // background-color: #ed4343;
    // color: white;
    background-color: #f9cccc !important; /* Lighter red background */
    color: #ed4343 !important; /* Darker red text */
    padding: 7px 15px !important;
    border-radius: 20px !important;
    // cursor: pointer;
    display: inline-block;
    font-size: 85%;
    font-weight: 600;
    line-height: 1;
    box-sizing: border-box;
}

.booking-planed-status {
    // background-color: #00cfe8;
    // color: white;
    background-color: #ccf6fa !important; /* Lighter cyan background */
    color: #00cfe8 !important; /* Darker cyan text */
    padding: 7px 15px !important;
    border-radius: 20px !important;
    // cursor: pointer;
    display: inline-block;
    font-size: 85%;
    font-weight: 600;
    line-height: 1;
    box-sizing: border-box;
}

.booking-managed-status {
    // background-color: #ff9f43;
    // color: white;
    background-color: #ffe5cc !important; /* Lighter orange background */
    color: #ff9f43 !important; /* Darker orange text */
    padding: 7px 15px !important;
    border-radius: 20px !important;
    // cursor: pointer;
    display: inline-block;
    font-size: 85%;
    font-weight: 600;
    line-height: 1;
    box-sizing: border-box;
}
.booking-assigned-status {
    // background-color: #008080;
    // color: white;
    background-color: #cceeee !important; /* Lighter teal background */
    color: #008080 !important; /* Darker teal text */
    padding: 7px 15px !important;
    border-radius: 20px !important;
    // cursor: pointer;
    display: inline-block;
    font-size: 85%;
    font-weight: 600;
    line-height: 1;
    box-sizing: border-box;
}

.completed-verification {
    // background-color: #fe8900;
    // color: white;
    background-color: #ffe0b3 !important; /* Lighter orange background */
    color: #fe8900 !important; /* Darker orange text */
    padding: 7px 15px !important;
    border-radius: 20px !important;
    // cursor: pointer;
    display: inline-block;
    font-size: 85%;
    font-weight: 600;
    line-height: 1;
    box-sizing: border-box;
}
.modern-status {
    min-width: 80px;
    // height: 25px;
    padding: 8px 15px !important;
    border-radius: 60px !important;
    cursor: default;
    display: flex;
    font-size: 85%;
    font-weight: 600;
    line-height: 1;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

.modern-status--success {
    background-color: #f1f7eb;
    color: #50843c;
}
.modern-status--danger {
    background-color: #fcdedc;
    color: #f51d2c;
}
.modern-status--sky {
    background-color: #0098b91a;
    color: #0098b9;
}
.modern-status--info {
    background-color: #f9b4001a;
    color: #f9b400;
}
.modern-status--cold {
    background-color: #001d871a;
    color: #001d87;
}
.modern-status--cold-sky {
    background-color: #0c32bd0d;
    color: #3258e4;
}
.modern-status--pink {
    background-color: #f51dec1a;
    color: #f51dec;
}
.modern-status--purple {
    background-color: #f0edf6;
    color: #8b428b;
}
.modern-status--warning {
    background-color: #dce1fc40;
    color: #3252ff;
}
.modern-status--brown {
    background-color: #fdf4e5;
    color: #b37818;
}
.modern-status--success-green {
    background-color: #50c87833;
    color: #33a942;
}

.filter .dropdown-menu {
    width: 400px !important;
    left: -170px !important;

    .filter-dropdown {
        padding: 20px;
        z-index: 99999;

        .search {
            position: absolute;
            right: 20px;
            top: 50%;
        }

        .datePopup {
            position: absolute;
            top: 130%;
            background: white;
            box-shadow: 2px 2px 10px #ddd;
        }

        .dates {
            position: absolute;
            left: 10px;
            top: 52%;
        }

        .days {
            display: flex;
            margin-top: 8px;
            flex-wrap: wrap;
            gap: 10px 10px;

            div {
                border: 1px solid #d8d6de;
                border-radius: 38px;
                padding: 4px 16px;
                color: rgba(110, 107, 123, 0.75);
                cursor: pointer;
            }

            .active {
                background: #ea2224;
                color: #fff;
                border: none;
            }
        }

        .button {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;

            button {
                width: 48%;
            }

            .butt {
                background: #ea2224 !important;
            }

            .cancel {
                background: none !important;
                color: #333333 !important;
                border-color: #d8d6de !important;
            }
        }
    }
}

.dropimg {
    width: 26px !important;
    height: 24px !important;
    border-radius: 50% !important;
    margin-right: 6px !important;
}

.customToggleBtn {
    border: 1px solid #ea2224 !important;
    border-color: #ea2224 !important;
}

.customToggleBtn1 {
    outline: #ea2224 !important;
}

.customOpt {
    padding: 8px 4px;
}

.customOpt:hover {
    background: #ea2224;
    color: #fff;
    cursor: pointer;
}

input[type='time'] {
    position: relative;
}

input[type='time']::-webkit-calendar-picker-indicator {
    display: block;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background: transparent;
    cursor: pointer;
}

.custom-item {
    padding: 4px 6px;
    padding-left: 16px;
}

.custom-item:hover {
    background-color: #fcdedc !important;
    cursor: pointer !important;
}

#custom-tg-export {
    border: 1px solid #d8d6de !important;
    background-color: transparent;

    &:hover:not(.disabled):not(:disabled) {
        background-color: #fff;
    }
}

.custom-tg-filter {
    border: 1px solid #d8d6de !important;
    background-color: #fff;

    &:hover:not(.disabled):not(:disabled) {
        background-color: #fff;
    }
    &:not(:disabled):not(.disabled):active:focus:focus-within {
        background-color: #fff;
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):focus-within {
        //   box-shadow:0 0 6px 1px  rgba(#ea2224, 0.6);
        background-color: #fff;
    }
}

.select__control {
    border-radius: 0px !important;
}

.form-control {
    border-radius: 0px !important; /* Set your desired border-radius */
}

.css-13cymwt-control {
    border-radius: 0px !important; /* Set your desired border-radius */
}

#no-option .active * {
    background-color: #ea22241a !important;
    color: #ea2224 !important ;
    border-bottom: 3px solid #ea2224 !important;
}

[dir='ltr']
    .input-group
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(
        .invalid-feedback
    ) {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

//**custom css for card
.card {
    border-radius: 0px !important;
}

// ** toggle column button
.tg-button {
    height: 39px !important;
}

[dir] textarea.form-control {
    border: 1px solid #d8d6de !important;
}

// ** create booking form
.form-outer {
    margin: 4px 16px 16px 16px !important;
    padding: 24px 12px !important;
    background-color: #fff !important;
}

.form-outer-create-invoice {
    margin: 4px 2px 42px 2px !important;
    padding: 24px 12px !important;
    background-color: #fff !important;
}

// ** changing Icon color
.icon-h-layout {
    color: #ea2224 !important;
}

.input-group-2 {
    padding-top: 0px !important ;
    padding-bottom: 0px !important ;
}

// ** Changed disable colors
// Todo: Mostafizur Rahaman
[dir] .form-control:disabled {
    // background-color: #fcdedc !important;
    background-color: #e5e5e5 !important;
}

// ** for removing border from input grouped icons
[dir] .input-group-text {
    border-left: 0px !important;
}

// **removing Resize from text-area
.no-resize {
    resize: none !important;
}

// **removing down arrow from popover
.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow {
    display: none !important;
}

// * Adjust transition for Navbar
.hide-nav {
    top: 0px !important;
    transition: transform 0.2s ease-in;
}

.hide-nav-top {
    // top: -100px !important;
    transform: translateY(-100%);
    transition: transform 0.2s ease-in;
}

// select input color
.select__control .select__single-value {
    color: #272727 !important ;
}

//table titles

.table-title {
    font-size: 18px;
    color: #ea2224;
    font-weight: 500;
}

.inactive-tab {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    border: 1px solid #dfdfdf;
    background: #fff;
    color: #868686;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.2s ease;
}
.inactive-tab:hover,
.inactive-tab-imp:hover {
    background: #f53b3d !important;
    color: #fff !important;
}

.active-tab {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    border: 1px solid #dfdfdf;
    background: #ea2224;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s ease;
}

.active-tab:hover,
.active-tab-imp:hover {
    background: #f53b3d !important;
}

.inactive-tab-imp {
    display: flex !important;
    padding: 8px 16px !important;
    justify-content: center !important;
    align-items: center !important;
    border: 1px solid #dfdfdf !important;
    background: #fff !important;
    color: #868686 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    cursor: pointer !important;
    transition: 0.3s all ease !important;
}

.active-tab-imp {
    display: flex !important;
    padding: 8px 16px !important;
    justify-content: center !important;
    align-items: center !important;
    border: 1px solid #dfdfdf !important;
    background: #ea2224 !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    cursor: pointer !important;
    transition: 0.3s all ease !important;
}

.col-sm-12 {
    padding: 0px 12px !important;
}

.col-md-6 {
    padding: 0px 12px !important;
}

.col-xl-6 {
    padding: 0px 12px !important;
}

.column-box {
    padding-right: 24px !important;
}

.ip-right {
    padding-right: 8px !important;
}

.ip-left {
    padding-left: 8px !important;
}

.column-box-half {
    padding-right: 12px !important;
}

.column-box-left {
    padding-left: 24px !important;
}

#radio-label {
    margin-bottom: 0px !important;
}

#label-m0 {
    margin-bottom: 0px !important;
}

.booking-text-area {
    line-height: 1.7rem !important;
}

#pay-button-row {
    padding: 0px 0px !important;
}

.column-box-x {
    padding: 0px 24px !important;
}

// .btn-rad{
//     width: 56px;
//     height: 56px;
//     border-color: #ea2224;
//     border-radius: 50%;
//     position: relative;
//     top: -40;
//     bottom: 0;
//     right: 10px;
//     margin: auto;
//     cursor: pointer;
//     padding: 8px;
// }

// .btn-rad-tgl{
//     opacity: 0;
//     pointer-events: none

// }

// .show-search{
//     width: 240px    ;
//     opacity: 1  ;
//     pointer-events: initial ;
//     transition: width .5s cubic-bezier(.9, 0, .3, .9)   ;
// }

[dir] .custom-toggle-hide.dropdown-toggle::after {
    background-image: none !important;
}

.checkbox-pmsn {
    border-radius: 0px !important;
}

.slider-info {
    display: flex;
    position: relative;
    width: 100%;
    gap: 4rem;
    margin: 24px 0px 16px 0px;
}

.swiper-slide {
    width: max-content !important;
}

.view-file-text {
    margin-top: 0.5rem;
    font-size: 18px;
    cursor: pointer;
}

.view-file-text:hover {
    text-decoration: underline;
    color: #ea2224;
}

.leaflet-routing-container {
    display: none !important;
}

.darkText {
    color: #232323;
}
.lightText {
    color: #a9a9a9 !important;
}

/* Specific scrollbar styles for nested elements with specific classes */
.activity-dropdown::-webkit-scrollbar {
    background-color: #fff !important;
    border-color: #fff !important;
}

.activity-dropdown::-webkit-scrollbar-thumb {
    /* Custom styles for the scrollbar thumb */
    background-color: #fff !important;
    border-color: #fff !important;
}

:where(.css-dev-only-do-not-override-14bt1ku).ant-picker-outlined.ant-picker-disabled,
:where(.css-dev-only-do-not-override-14bt1ku).ant-picker-outlined[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fcdedc;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: pointer;
    opacity: 1;
}

:where(.css-dev-only-do-not-override-14bt1ku).ant-picker-outlined.ant-picker-disabled input[disabled],
:where(.css-dev-only-do-not-override-14bt1ku).ant-picker-outlined[disabled] input[disabled] {
    cursor: pointer;
    background-color: #fcdedc;
}

:where(.css-dev-only-do-not-override-14bt1ku).ant-picker .ant-picker-input > input[disabled] {
    background-color: #fcdedc;
    color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

:where(.css-dev-only-do-not-override-14bt1ku).ant-picker-outlined.ant-picker-disabled:hover:not([disabled]),
:where(.css-dev-only-do-not-override-14bt1ku).ant-picker-outlined[disabled]:hover:not([disabled]) {
    border-color: #d9d9d9;
    background-color: #fcdedc;
}

:where(.css-14bt1ku).ant-picker-outlined.ant-picker-disabled,
:where(.css-14bt1ku).ant-picker-outlined[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fcdedc;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: pointer;
    opacity: 1;
}
:where(.css-14bt1ku).ant-picker .ant-picker-input > input[disabled] {
    background-color: #fcdedc;
    color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
}
:where(.css-14bt1ku).ant-picker-outlined.ant-picker-disabled input[disabled],
:where(.css-14bt1ku).ant-picker-outlined[disabled] input[disabled] {
    cursor: pointer;
}

.rad-btn:disabled {
    background-color: #fcdedc !important;
}

.left-col-p-full {
    border-left: 1.5px solid #dfdfdf !important;
    padding-left: 24px !important;
}

.danger-filled {
    background-color: #ed4343;
    color: white;
    padding: 7px 15px !important;
    border-radius: 20px !important;
    cursor: pointer;
    display: inline-block;
    font-size: 85%;
    font-weight: 600;
    line-height: 1;
    box-sizing: border-box;
}
.warning-filled {
    background-color: #fe8900;
    color: white;
    padding: 7px 15px !important;
    border-radius: 20px !important;
    cursor: pointer;
    display: inline-block;
    font-size: 85%;
    font-weight: 600;
    line-height: 1;
    box-sizing: border-box;
}
.success-filled {
    background-color: #50c878;
    color: white;
    padding: 7px 15px !important;
    border-radius: 20px !important;
    cursor: pointer;
    display: inline-block;
    font-size: 85%;
    font-weight: 600;
    line-height: 1;
    box-sizing: border-box;
}

.filter-padding {
    padding: 11px 0px 11px 10px !important;
}

.hover-primary:hover {
    color: #ea2224;
}

.csv-button {
    background-color: #ea2224;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 6px;
    max-width: fit-content;
    border-radius: 0px;
}

.month-date-ip {
    width: calc(100% - 4rem) !important;
}

.month-date-ip-group {
    padding: 6px 10px !important;
}

/* Add these styles to your CSS file */
.custom-link {
    cursor: pointer;
    color: #000;
    text-decoration: none; /* Remove underline */
}

.custom-link:hover {
    color: #ea2224 !important;
}

.custom-link:active {
    color: #ea2224 !important;
}

@media screen and (min-width: 300px) and (max-width: 768px) {
    .rp-left-col {
        border-left: none !important;
        // border-top: 1.5px solid #DFDFDF !important;
        margin-top: 1rem !important;
        padding-left: 0px !important;
    }
    .dropdown-notification {
        display: none !important;
    }
    .logo-mini {
        max-width: 104px !important;
    }
    .header-mini-button {
        padding: 8px !important;
    }

    .margin-top-divider {
        margin-top: 2rem !important;
    }

    .margin-t-one {
        margin-bottom: 1rem !important;
    }
    .mainLogo {
        margin-left: 0px !important;
        margin-top: 0px !important;
    }
    .modal-resp {
        max-width: 100% !important;
        max-height: 80vh !important;
        margin-bottom: 2rem !important;
        // overflow-y: scroll !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .rp-left-col {
        border-top: '1.5px solid #DFDFDF';
        border-left: none !important;
    }
}
@media screen and (min-width: 0px) and (max-width: 768px) {
    .ip-right {
        padding-right: 0px !important;
    }
    .ip-right {
        padding-right: 0px !important;
    }

    .w98 .input-group,
    .postalCode {
        width: 96% !important;
    }
}

.time_based_filter {
    .time_based_days {
        display: flex;
        margin-top: 8px;
        flex-wrap: wrap;
        gap: 10px 10px;

        div {
            border: 1px solid #d8d6de;
            border-radius: 38px;
            padding: 4px 16px;
            color: rgba(110, 107, 123, 0.75);
            cursor: pointer;
        }

        .active {
            background: #ea2224;
            color: #fff;
            border: none;
        }
    }

    .button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        button {
            width: 48%;
        }

        .butt {
            background: #ea2224 !important;
        }

        .cancel  {
            background: none !important;
            color: #333333 !important;
            border-color: #d8d6de !important;
        }
    }
}
