@import '../../../base/bootstrap-extended/include';
@import '../../../base/components/include';
@import '../../../base/core/colors/palette-variables';

.react-hot-toast {
    font-size: 1rem !important;
    color: $body-color !important;
    letter-spacing: 0.14px !important;
    border-radius: $border-radius !important;
    box-shadow: 0px 4px 10px -4px rgb(58 53 65 / 60%) !important;
}

// Dark Layout
.dark-layout {
    .react-hot-toast {
        color: $theme-dark-body-color !important;
        background: $theme-dark-card-bg !important;
        box-shadow: 0px 8px 16px -4px rgb(19 17 32 / 65%) !important;
        letter-spacing: 0.14px !important;
        border-radius: $border-radius !important;
    }
}
